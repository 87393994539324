import './App.css';

import {
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import Main from './pages/Main';
import Success from './pages/Success';

function App() {
  return (
    <HashRouter >
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
