import React from 'react'

const Success = () => {
  return (
    <div>
      <center><h1>Заявка переведена в обработку</h1></center>
      <p style={{
        marginLeft: '15%',
        marginRight: '15%',
      }}>* У пользователей сервиса есть возможность получить займ с минимальной процентной ставкой 0%. Подробности при выборе персонального предложения. Займ у партнеров выдается в российских рублях гражданам Российской Федерации, на банковский счет, на карту или наличными. Минимальная сумма займа: 1 000 рублей. Максимальная сумма займа: 60 000 рублей. Процентная ставка: от 0% годовых до 657% годовых, срок займа от 1 до 365 дней включительно. Компания является оператором персональных данных. Сервис оказывает платные услуги по подбору кредитных продуктов для клиентов, а именно предлагает клиенту список предложений кредитных учреждений и прочих финансовых организаций, в которые клиент может обратиться с целью оформления заявки на кредитный продукт. Сервис не является банком или кредитором, не относится к финансовым учреждениям и не несёт ответственности за последствия любых заключенных договоров кредитования или условия по ним. Минимальная процентная ставка у некоторых партнеров составляет 0%. Чтобы оформить заявку на получение займа, необходимо заполнить анкету на сайте проекта.</p>
    </div>
  )
}

export default Success